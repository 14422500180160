export class StudentClassModel {
    studentClassId: string;
    studentId: string;
    classId: string;

    constructor(studentId: string, classId: string) {
        this.studentId = studentId;
        this.classId = classId;
        this.studentClassId = studentId + "_" + classId
    }
}
