import Dexie from 'dexie';
import dbSchema from './db.schema';
import ssdbSchema from './ssdb.schema';


export default async function installDB(): Promise<any> {
  // We are installing the databases sequentially because Chrome would choke if we did them together
  const db = new Dexie('LightSailDB');
  dbSchema(db);
  try {
    await db.open();
    await db.close();
  } catch (err) {
    handleError(err);
  }

  const ssdb = new Dexie('SelfServiceDB');
  ssdbSchema(ssdb);
  try {
    await ssdb.open();
    await ssdb.close();
  } catch (err) {
    handleError(err);
  }
}

function handleError(err): void {
  if (err.message.indexOf('than the existing version') === -1) {
    console.error('Failed to initialize IndexedDB', err);
    throw err;
  }
}
