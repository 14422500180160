export class IndexedDBContext {
    databaseName: string;
    database: IDBDatabase;

    constructor(databaseName: string, databaseVersion?: number) {
    this.databaseName = databaseName;
    this.database = null;
}

    validateTableExist(tableName: string) {
        return this.database.objectStoreNames.contains(tableName);
    };

    validate(tableName: string, functionName: string, reject: Function) {
        if (!this.database) {
            reject('LS: You need to create a database before you query it!');
        }
        if (!this.validateTableExist(tableName)) {
            reject(`LS: Invoke by ${functionName}. Table does not exist: ${tableName}. Database version: ${this.database.version}`);
        }
    }

}
