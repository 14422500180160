import {Injectable} from '@angular/core';
import {IndexedDBService} from '../indexedDB/indexedDB.service';
import {GlobalTableNames} from '../globals';

@Injectable()
export class BookMetadataService {

    constructor(private _db: IndexedDBService) {
    }

    private _fastCache = new Map();

    async save(key: string, value): Promise<any> {
        const bookMetadata = {
            bookCoverUrl: key,
            bookCoverImageData: value
        };

        this._fastCache.set(bookMetadata.bookCoverUrl, bookMetadata.bookCoverImageData);
        this._db.insert(GlobalTableNames.BookMetaData, bookMetadata);
    }

    async get<T = any>(key: string, checkExpired: boolean = false): Promise<T> {
        if (this._fastCache.has(key)) {
            return this._fastCache.get(key);
        }

        const res = await this._db.getByKey(GlobalTableNames.BookMetaData, key);
        if (res) {
            this._fastCache.set(res.bookCoverUrl, res.bookCoverImageData);
            return res.bookCoverImageData;
        }

        return <any>'';
    }

    async deleteByKey(key: string): Promise<any> {
        if (this._fastCache.has(key)) {
            this._fastCache.delete(key);
        }
        this._db.deleteByKey(GlobalTableNames.BookMetaData, key);
    }

  async clearLibraryIdsCacheIfNeeded() {
    const clearLibraryIdsCache = await this.get('clearLibraryIdsCache');
    if (clearLibraryIdsCache && Boolean(clearLibraryIdsCache)) {
      const items = await this._db.getAll(GlobalTableNames.BookMetaData);
      const libraryIdsCache = items.filter(item => item.bookCoverUrl.includes('/LibraryIds'));
      libraryIdsCache.map(cache => this.deleteByKey(cache.bookCoverUrl));
      this.deleteByKey('clearLibraryIdsCache');
    }
  }
}
