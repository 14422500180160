import {UUID} from 'angular2-uuid';
import {PageSessionSource} from '../enums/page-session-source.enum';
import {WordRuleType} from './word-list/enums/word-rule-type';

export class PageSession {
    requestId: string;
    startTime: Date;
    endTime: Date;
    userId: string;
    userGroupId: string;
    borrowedBookId: string;
    pageStart: number;
    pageEnd: number;
    locationStart: string;
    locationEnd: string;
    wordCount: number;
    isOutsideSchoolHours: boolean;
    IsBookComplete: boolean = false;
    source: PageSessionSource;
    homeworkAssignmentIds: string[];
    studentPracticeWordId: string;
    wordRuleType: WordRuleType;

    constructor() {
        this.requestId = UUID.UUID();
    }
}
