export class TeacherClassModel {
    teacherClassId: string;
    teacherId: string;
    classId: string;

    constructor(teacherId: string, classId: string) {
        this.teacherId = teacherId;
        this.classId = classId;
        this.teacherClassId = teacherId + "_" + classId
    }
}