import {Injectable} from '@angular/core';
import {isNullOrUndefined} from 'app/shared/utils/global.utils';
import {GlobalConstants} from '../globals';
import {AdminType} from '../enums/admin-type.enum';
import {UserType} from '../enums';

@Injectable({
    providedIn: 'root'
})

export class SessionStorageService {

    constructor() {

    }

    static Save(key, value): boolean {
        window.sessionStorage.setItem(key, value);
        return true;
    }

    static Get<T = string>(key: string): T {
        return window.sessionStorage.getItem(key) as unknown as T;
    }

    static Delete(key): boolean {
        window.sessionStorage.removeItem(key);
        return true;
    }

    static DeleteAll(hardDelete: boolean = false): boolean {
        let keptPath = '';
        const cleverInput = window.sessionStorage.getItem('cleverInput');
        const cleverApp = window.sessionStorage.getItem('cleverApp');
        const classLinkPath = window.sessionStorage.getItem('classlink');
        const jwtTokenPath = window.sessionStorage.getItem('jwtToken');
        let msssoTokenPath = window.sessionStorage.getItem('mssso');
        const demoStudentInd = SessionStorageService.Get(GlobalConstants.DemoStudentInd);
        const dTUserName = SessionStorageService.Get(GlobalConstants.DTUserName);
        const dTClassId = SessionStorageService.Get(GlobalConstants.DTClassId);
        const classId = SessionStorageService.Get(GlobalConstants.ClassId);
        const eventHubInfo = SessionStorageService.Get(GlobalConstants.EventHubUrl);

        if ((window.sessionStorage.length === 1)) {
            keptPath = window.sessionStorage.getItem('path');
            if (isNullOrUndefined((keptPath))) {
                keptPath = '';
            }
        }
        window.sessionStorage.clear();
        if (!hardDelete) {
            if (cleverApp !== '') {
                window.sessionStorage.setItem('cleverApp', cleverApp);
            }
            if (cleverInput !== '') {
                window.sessionStorage.setItem('cleverInput', cleverInput);
            }
            if (classLinkPath !== '') {
                window.sessionStorage.setItem('classlink', classLinkPath);
            }
            if (jwtTokenPath !== '') {
                window.sessionStorage.setItem('jwtToken', jwtTokenPath);
            }
            if (msssoTokenPath != '') {
                window.sessionStorage.setItem('mssso', msssoTokenPath);
            }
            if (keptPath !== '') {
                window.sessionStorage.setItem('path', keptPath);
            }
            if (demoStudentInd !== '' && !isNullOrUndefined(demoStudentInd)) {
                SessionStorageService.Save(GlobalConstants.DemoStudentInd, demoStudentInd);
            }
            if (dTUserName !== '' && !isNullOrUndefined(dTUserName)) {
                SessionStorageService.Save(GlobalConstants.DTUserName, dTUserName);
            }
            // preserve for the teacher/student switch to keep track of class changes
            if (dTClassId !== '' && !isNullOrUndefined(dTClassId)) {
                SessionStorageService.Save(GlobalConstants.DTClassId, dTClassId);
            }
            // preserve class selected from user profile/ global header sent to self service
            if (classId !== '' && !isNullOrUndefined(classId)) {
                SessionStorageService.Save(GlobalConstants.ClassId, classId);
            }
        }

        if (eventHubInfo !== '' && !isNullOrUndefined(eventHubInfo)) {
            SessionStorageService.Save(GlobalConstants.EventHubUrl, eventHubInfo);
        }

        SessionStorageService.configureTrackJsUserInfo();

        return true;
    }

    static configureTrackJsUserInfo() {
        // Set TrackJS MetaData: Begin
        const tracker = window.TrackJS;
        if (!isNullOrUndefined(tracker)) {
            tracker.configure({
                userId: SessionStorageService.Get(GlobalConstants.UserId) || 'not logged in'
            });

            const userType = SessionStorageService.Get(GlobalConstants.UserTypeId);
            const userRole = isNullOrUndefined(userType) ? 'n/a' : UserType[+userType];
            tracker.addMetadata('user_role', userRole);

            const adminLevel = SessionStorageService.Get(GlobalConstants.AdminType);
            const adminRole = isNullOrUndefined(adminLevel) ? 'n/a' : AdminType[+adminLevel];
            tracker.addMetadata('admin_role', adminRole);
            tracker.addMetadata('username', SessionStorageService.Get(GlobalConstants.UserName));
        }
        // Set TrackJS MetaData: End
    }
}
