import {Injectable} from '@angular/core';
import {IndexedDBService} from '../indexedDB/indexedDB.service';
import {AppStateConstants, GlobalConstants, GlobalTableNames} from '../globals';
import {SessionStorageService} from '../localstorage/session-storage.service';
import {AppStateService} from '../app.service';
import {LoggerService} from '../services/logger.service';
import {isNullOrUndefined} from 'app/shared/utils/global.utils';

@Injectable({providedIn: 'root'})
export class UserMetadataService {
    userId: string;
    //username:string;

    constructor(private _appState: AppStateService, private _db: IndexedDBService) {
        this._appState.set(AppStateConstants.browserOK, this.detectBrowser());
        if (this._appState.get(AppStateConstants.browserOK)) {
            this.userId = SessionStorageService.Get(GlobalConstants.UserId);
        }
    }

    save(key: string, value, userId: string = null, cacheMinutes = GlobalConstants.UserCacheFirstTimeout): Promise<any> {
        const expiryTime = new Date();
        expiryTime.setMinutes(expiryTime.getMinutes() + cacheMinutes);
        let userMetadata = {
            userMetadataKey: `${userId || this.userId}-${key}`,
            userId: this.userId,
            userMetadataValue: value,
            cacheExpireDateTime: expiryTime.toISOString()
        };
        return new Promise<any>((resolve, reject) => {
            this._db.insert(GlobalTableNames.UserMetaData, userMetadata).then((response) => {
                resolve(response);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    get<T = any>(key: string, checkExpired: boolean = false, userId: string = null): Promise<T> {
        return this._db.getByKey(GlobalTableNames.UserMetaData, `${userId || this.userId}-${key}`).then((res) => {
            if (isNullOrUndefined(res)) {
                return '';
            } else {
                let dataFresh = true;
                if (checkExpired) {
                    try {
                        const ExpiryDate = isNullOrUndefined(res.cacheExpireDateTime) ? new Date('1 Jan 1900') : new Date(res.cacheExpireDateTime);
                        dataFresh = ExpiryDate.getTime() >= (new Date().getTime());
                    } catch (err) {
                        LoggerService.Error('UserMetaData Cache Get Error: ', err);
                        dataFresh = false;
                    }
                }

                return dataFresh ? res.userMetadataValue : '';
            }
        }).catch((error) => {
            LoggerService.Error('', error);
        });
    }

    deleteByKey(key: string): Promise<any> {
        return this._db.deleteByKey(GlobalTableNames.UserMetaData, this.userId + '-' + key).then((result) => {
            return result;
        });
    }

    deleteAll(): Promise<any> {
        return this._db.deleteByIndex(GlobalTableNames.UserMetaData, "userId", this.userId).then((result) => {
            return result;
        });
    }

    setUser(userId: string) {
        this.userId = userId;
    }

    private detectBrowser(): boolean {
        const vendor = window.navigator.vendor;

        const regex_silk = /(Silk\/)/; //test if browser is silk
        const isSilk = regex_silk.test(window.navigator.userAgent);

        let browserOk = (vendor.indexOf("Google") != -1) && !isSilk;
        let is_safari = vendor.indexOf('Apple') > -1 && !isSilk;
        const regex_edge = /(xyzzy-izzy-ok\/)/; // /(Edge\/)/;
        const isEdge = regex_edge.test(window.navigator.userAgent);

        const regex_ios = /iP(hone|od|ad)/;
        const regex_ios11 = /(OS )([^0-9]\d|1[1-9]\d{0}|[2-9]\d{1,})/; //regex to test for IOS 11 and up to 100
        const iOS_11up = regex_ios.test(window.navigator.userAgent) && regex_ios11.test(window.navigator.userAgent);
        const iPad_OS = !regex_ios.test(window.navigator.userAgent) && window.navigator.maxTouchPoints !== undefined;

        let regex_chromeV = / Chrome\/(5[5-9]|[6-9]\d|\d{3,})\./; //match Chrome/55. format
        const isChromeV = regex_chromeV.test(window.navigator.userAgent);

        if (!browserOk) {
            const supportServiceWorker = navigator.serviceWorker;
            const supportStorage = 'webkitPersistentStorage' in navigator;
            browserOk = is_safari && (iOS_11up || iPad_OS || (supportServiceWorker && supportStorage));
            browserOk = browserOk || isEdge;
        } else {
            browserOk = isChromeV;
        }
        this._appState.set(AppStateConstants.IsSafari, is_safari);
        return browserOk == undefined ? false : browserOk;
    }
}
