import {Injectable} from '@angular/core';
import {IndexedDBService} from '../indexedDB/indexedDB.service';
import {ApiClientOptions, ApiQueueModel} from './models';
import {GlobalTableNames} from '../globals';
import {HttpAction} from './enums';
import {Observable, Subject} from 'rxjs';
import {ApiLogModel} from './models/api-log.model';
import {GlobalConstants} from '../globals/global-constants';

@Injectable()
export class ApiQueueService {
    private _queueChangeSubject: Subject<ApiQueueModel> = new Subject<ApiQueueModel>();
    readonly entryAdded: Observable<ApiQueueModel>;

    constructor(private _db: IndexedDBService) {
        this.entryAdded = this._queueChangeSubject.asObservable();
    }

    addNonGetCall<T>(serverUrl: string, path: any, action: HttpAction, content: any,
                     options: ApiClientOptions = new ApiClientOptions()): Promise<T> {
        let data = new ApiQueueModel();
        data.action = action.toString();
        data.serverUrl = serverUrl;
        data.path = path;
        data.content = content;
        data.processInd = 0;
        data.storeAfterProcessing = options.storeAfterProcessing;
        data.isEventHub = options.isEventHub;
        data.messageType = options.messageType;
        data.userGroupOrganizationIdHeader = options.userGroupOrganizationIdHeader;
        data.schoolIdHeader = options.schoolIdHeader;
        data.userIdHeader = options.userIdHeader;
        return this._db.insert(GlobalTableNames.ApiQueue, data).then(newKey => {
                this._db.getByKey(GlobalTableNames.ApiQueue, newKey).then(newData => {
                    this._queueChangeSubject.next(newData);
                });
                return newKey;
            }
        );
    }

    getBacklogApiQueue(cleanProcessedEntries: boolean): Observable<ApiQueueModel> {
        return this._db.getCurrentApiQueue(cleanProcessedEntries);
    }

    update(response: any): Promise<any> {
        return this._db.update(GlobalTableNames.ApiQueue, response);
    }

    deleteRow(apiQueueId: number): Promise<any> {
        return this._db.deleteByKey(GlobalTableNames.ApiQueue, apiQueueId);
    }

    writeToLog(logEntry: ApiLogModel): Promise<any> {
        return this._db.AppendWithMaxRows(GlobalTableNames.ApiLog, logEntry, GlobalConstants.MaxApiLogEntries).then();
    }
}
