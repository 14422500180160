import Dexie, {Transaction} from 'dexie';

// To modify the schema, add a new entry at the end of the function, following the existing pattern.
// If data migration is also needed, .upgrade(...) can be added too. See the Dexie documentation.

export default function dbSchema(db: Dexie) {
  db.version(2.6).stores({ // version number divided by 10 here.
    BookCoverQueue: 'queuePath,&queuePath,queuePriority',
    BookMetaData: 'bookCoverUrl,&bookCoverUrl',
    BorrowedBook: 'borrowedBookId,&borrowedBookId,userId',
    Class: 'classId,&classId,userGroupId',
    LoginCredentials: 'username,&username',
    MsgQueue: '++queueId,&queueId',
    School: 'schoolId,&schoolId',
    SchoolClasses: '[schoolId+classId],schoolId,&[schoolId+classId]',
    SchoolMetaData: 'schoolId,&schoolId',
    Student: 'studentId,&studentId',
    StudentClass: '++studentClassId,classId,&studentClassId,studentId',
    SysInfo: 'objectId,&objectId',
    Teacher: 'teacherId,&teacherId',
    TeacherClass: '++teacherClassId,classId,&teacherClassId,teacherId',
    UserMetaData: 'userMetadataKey,userId,&userMetadataKey',
    UserSession: 'offlineSessionId,&offlineSessionId',
    userCompletedTexts: 'userId,&userId',
    userPowerText: 'userId,&userId',
  });

  db.version(4.4).stores({ // version number divided by 10 here.
    CommonCoreSubStandard: 'commonCoreSubStandardId,&commonCoreSubStandardId',
    ReadingTag: 'readingTagId,&readingTagId',
  }).upgrade((tran: Transaction) => {
    return installReadingTags(tran);
  });

  db.version(4.5).stores({
    Rubric: 'rubricId,&rubricId',
  });

  db.version(5.0).stores({
    AssessmentAnswer: '[userId+borrowedBookId+assessmentQuestionId],[userId+borrowedBookId],&[userId+borrowedBookId+assessmentQuestionId]',
    BookCache: 'bookId,&bookId',
    BookDetailQueue: 'queuePath,&queuePath,queuePriority',
    CommonCoreGradeSubStandard: 'grade_classificationId_subStandardId,grade_classificationId,&grade_classificationId_subStandardId',
    GradeReadingTag: 'grade_readingTagId_classificationId,grade_classificationId,&grade_readingTagId_classificationId',
    LoginCredentials: 'username,loginProviderType,&username',
    MsgLog: 'queueId,&queueId',
    SchoolLibraryIds: 'schoolId,&schoolId',
    SchoolWeek: 'schoolAndGrade,&schoolAndGrade',
    StatusColumns: '[teacherId+classId+columnName],&[teacherId+classId+columnName]',
    Student: 'studentId,&studentId,userId',
    StudentSettings: '[studentId+classId+settingKey],&[studentId+classId+settingKey]',
    Teacher: 'teacherId,&teacherId,userId',
    Thought: 'thoughtId,*teacherShareClassList,&thoughtId,[userId+bookId],[userId+bookId+classId]',
    ThoughtComment: 'thoughtCommentId,&thoughtCommentId,thoughtId',
    Vacations: 'schoolId,&schoolId',
    rc: '[bId+pid],&[bId+pid],lastAccessedDate',
  }).upgrade(function(upgrTran) {
    return upgrTran.table('Thought').clear()
        .then(() => upgrTran.table('ThoughtComment').clear())
        .then(() => installGradeReadingTags(upgrTran));
  });

  db.version(5.1).stores({
    BorrowedBook: 'borrowedBookId,&borrowedBookId,userId',
    userPowerText: 'userId,&userId',
    Rubric: 'rubricId,&rubricId',
    AcademicYearSchoolClasses: '[schoolId+academicYearId], &[schoolId+academicYearId]',
  });

  db.version(5.2).stores({
    // eslint-disable-next-line max-len
    ClassSettings: '[classId+studentId+settingKey+settingType],[classId+settingKey+settingType],&[classId+studentId+settingKey+settingType]',
    AccountSettings: 'userId,&userId',
  });

  db.version(5.3).upgrade(function (upgrTran) {
    return upgrTran.table('BookMetaData').clear();
  });

  db.version(5.4).stores({
    StudentLexileList: '[studentId+classId],classId,&[studentId+classId]',
  });

  db.version(5.5).stores({
    EventQueue: '++id',
  });

  db.version(5.6).stores({
    PowerChallenge: '[userId+studentPlacementTestId+testId+testQuestionId],userId,&[userId+studentPlacementTestId+testId+testQuestionId]',
  });

  db.version(5.7).stores({
    PowerChallenge: '[userId+studentPlacementTestId+testId+testQuestionId],userId,&[userId+studentPlacementTestId+testId+testQuestionId],studentPlacementTestId',
  });

  // Populate data
  db.on('populate', () => {
    installReadingTags(db);
    installGradeReadingTags(db);
  });
}

// This might be a populate or an upgrade, so break it out into a function
function installReadingTags(dbOrTran: any) {
  const CommonCoreSubStandards = [];
  const ReadingTags = [];

  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 1,
    'commonCoreStandardId': 1,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_1'
  });
  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 2,
    'commonCoreStandardId': 1,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_1'
  });
  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 3,
    'commonCoreStandardId': 1,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_1'
  });
  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 4,
    'commonCoreStandardId': 2,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_2'
  });
  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 5,
    'commonCoreStandardId': 2,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_2'
  });
  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 6,
    'commonCoreStandardId': 2,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_2'
  });
  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 7,
    'commonCoreStandardId': 3,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_3'
  });
  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 8,
    'commonCoreStandardId': 3,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_3'
  });
  CommonCoreSubStandards.push({
    'commonCoreSubStandardId': 9,
    'commonCoreStandardId': 3,
    'commonCoreStandardName': 'COMMONCORE_NAME.TAG_3'
  });

  ReadingTags.push({
    'readingTagId': 1,
    'abbreviation': 'READINGTAG_ABB.ABB.1',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.1',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.1'
  });
  ReadingTags.push({
    'readingTagId': 2,
    'abbreviation': 'READINGTAG_ABB.ABB.2',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.2',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.2'
  });
  ReadingTags.push({
    'readingTagId': 3,
    'abbreviation': 'READINGTAG_ABB.ABB.3',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.3',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.3'
  });
  ReadingTags.push({
    'readingTagId': 4,
    'abbreviation': 'READINGTAG_ABB.ABB.4',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.4',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.4'
  });
  ReadingTags.push({
    'readingTagId': 5,
    'abbreviation': 'READINGTAG_ABB.ABB.5',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.5',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.5'
  });
  ReadingTags.push({
    'readingTagId': 6,
    'abbreviation': 'READINGTAG_ABB.ABB.6',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.6',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.6'
  });
  ReadingTags.push({
    'readingTagId': 7,
    'abbreviation': 'READINGTAG_ABB.ABB.7',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.7',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.7'
  });
  ReadingTags.push({
    'readingTagId': 8,
    'abbreviation': 'READINGTAG_ABB.ABB.8',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.8',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.8'
  });
  ReadingTags.push({
    'readingTagId': 9,
    'abbreviation': 'READINGTAG_ABB.ABB.9',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.9',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.9'
  });
  ReadingTags.push({
    'readingTagId': 10,
    'abbreviation': 'READINGTAG_ABB.ABB.10',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.10',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.10'
  });
  ReadingTags.push({
    'readingTagId': 11,
    'abbreviation': 'READINGTAG_ABB.ABB.11',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.11',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.11'
  });
  ReadingTags.push({
    'readingTagId': 12,
    'abbreviation': 'READINGTAG_ABB.ABB.12',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.12',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.12'
  });
  ReadingTags.push({
    'readingTagId': 13,
    'abbreviation': 'READINGTAG_ABB.ABB.13',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.13',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.13'
  });
  ReadingTags.push({
    'readingTagId': 14,
    'abbreviation': 'READINGTAG_ABB.ABB.14',
    'shorterVersion': 'READINGTAG_SV.SHORTVERSION.14',
    'anchorTag': 'READINGTAG_AT.ANCHORTAG.14'
  });

  dbOrTran.table('CommonCoreSubStandard').bulkAdd(CommonCoreSubStandards);
  dbOrTran.table('ReadingTag').bulkAdd(ReadingTags);
}

// This might be a populate or an upgrade, so break it out into a function
function installGradeReadingTags(dbOrTran: any) {
  const GradeReadingTags = [];
  const CommonCoreGradeSubStandards = [];

  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_10_1',
    'tagName': 'READINGTAG_NAME.0_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_10_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_10_2',
    'tagName': 'READINGTAG_NAME.0_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_10_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_11_1',
    'tagName': 'READINGTAG_NAME.0_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_11_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_11_2',
    'tagName': 'READINGTAG_NAME.0_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_11_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_12_1',
    'tagName': 'READINGTAG_NAME.0_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_12_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_12_2',
    'tagName': 'READINGTAG_NAME.0_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_12_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_13_1',
    'tagName': 'READINGTAG_NAME.0_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_13_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_13_2',
    'tagName': 'READINGTAG_NAME.0_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_13_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_14_1',
    'tagName': 'READINGTAG_NAME.0_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_14_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_14_2',
    'tagName': 'READINGTAG_NAME.0_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_14_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_1_1',
    'tagName': 'READINGTAG_NAME.0_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_1_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_1_2',
    'tagName': 'READINGTAG_NAME.0_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_1_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_2_1',
    'tagName': 'READINGTAG_NAME.0_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_2_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_2_2',
    'tagName': 'READINGTAG_NAME.0_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_2_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_3_1',
    'tagName': 'READINGTAG_NAME.0_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_3_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_3_2',
    'tagName': 'READINGTAG_NAME.0_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_3_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_4_1',
    'tagName': 'READINGTAG_NAME.0_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_4_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_4_2',
    'tagName': 'READINGTAG_NAME.0_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_4_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_5_1',
    'tagName': 'READINGTAG_NAME.0_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_5_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_5_2',
    'tagName': 'READINGTAG_NAME.0_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_5_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_6_1',
    'tagName': 'READINGTAG_NAME.0_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_6_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_6_2',
    'tagName': 'READINGTAG_NAME.0_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_6_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_7_1',
    'tagName': 'READINGTAG_NAME.0_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_7_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_7_2',
    'tagName': 'READINGTAG_NAME.0_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_7_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_8_1',
    'tagName': 'READINGTAG_NAME.0_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_8_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_8_2',
    'tagName': 'READINGTAG_NAME.0_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_8_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_9_1',
    'tagName': 'READINGTAG_NAME.0_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_9_1',
    'grade_classificationId': '0_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 0,
    'grade_readingTagId_classificationId': '0_9_2',
    'tagName': 'READINGTAG_NAME.0_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.0_9_2',
    'grade_classificationId': '0_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_10_1',
    'tagName': 'READINGTAG_NAME.10_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_10_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_10_2',
    'tagName': 'READINGTAG_NAME.10_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_10_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_11_1',
    'tagName': 'READINGTAG_NAME.10_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_11_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_11_2',
    'tagName': 'READINGTAG_NAME.10_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_11_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_12_1',
    'tagName': 'READINGTAG_NAME.10_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_12_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_12_2',
    'tagName': 'READINGTAG_NAME.10_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_12_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_13_1',
    'tagName': 'READINGTAG_NAME.10_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_13_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_13_2',
    'tagName': 'READINGTAG_NAME.10_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_13_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_14_1',
    'tagName': 'READINGTAG_NAME.10_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_14_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_14_2',
    'tagName': 'READINGTAG_NAME.10_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_14_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_1_1',
    'tagName': 'READINGTAG_NAME.10_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_1_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_1_2',
    'tagName': 'READINGTAG_NAME.10_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_1_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_2_1',
    'tagName': 'READINGTAG_NAME.10_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_2_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_2_2',
    'tagName': 'READINGTAG_NAME.10_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_2_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_3_1',
    'tagName': 'READINGTAG_NAME.10_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_3_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_3_2',
    'tagName': 'READINGTAG_NAME.10_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_3_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_4_1',
    'tagName': 'READINGTAG_NAME.10_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_4_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_4_2',
    'tagName': 'READINGTAG_NAME.10_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_4_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_5_1',
    'tagName': 'READINGTAG_NAME.10_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_5_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_5_2',
    'tagName': 'READINGTAG_NAME.10_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_5_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_6_1',
    'tagName': 'READINGTAG_NAME.10_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_6_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_6_2',
    'tagName': 'READINGTAG_NAME.10_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_6_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_7_1',
    'tagName': 'READINGTAG_NAME.10_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_7_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_7_2',
    'tagName': 'READINGTAG_NAME.10_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_7_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_8_1',
    'tagName': 'READINGTAG_NAME.10_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_8_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_8_2',
    'tagName': 'READINGTAG_NAME.10_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_8_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_9_1',
    'tagName': 'READINGTAG_NAME.10_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_9_1',
    'grade_classificationId': '10_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 10,
    'grade_readingTagId_classificationId': '10_9_2',
    'tagName': 'READINGTAG_NAME.10_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.10_9_2',
    'grade_classificationId': '10_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_10_1',
    'tagName': 'READINGTAG_NAME.11_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_10_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_10_2',
    'tagName': 'READINGTAG_NAME.11_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_10_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_11_1',
    'tagName': 'READINGTAG_NAME.11_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_11_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_11_2',
    'tagName': 'READINGTAG_NAME.11_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_11_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_12_1',
    'tagName': 'READINGTAG_NAME.11_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_12_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_12_2',
    'tagName': 'READINGTAG_NAME.11_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_12_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_13_1',
    'tagName': 'READINGTAG_NAME.11_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_13_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_13_2',
    'tagName': 'READINGTAG_NAME.11_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_13_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_14_1',
    'tagName': 'READINGTAG_NAME.11_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_14_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_14_2',
    'tagName': 'READINGTAG_NAME.11_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_14_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_1_1',
    'tagName': 'READINGTAG_NAME.11_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_1_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_1_2',
    'tagName': 'READINGTAG_NAME.11_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_1_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_2_1',
    'tagName': 'READINGTAG_NAME.11_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_2_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_2_2',
    'tagName': 'READINGTAG_NAME.11_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_2_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_3_1',
    'tagName': 'READINGTAG_NAME.11_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_3_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_3_2',
    'tagName': 'READINGTAG_NAME.11_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_3_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_4_1',
    'tagName': 'READINGTAG_NAME.11_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_4_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_4_2',
    'tagName': 'READINGTAG_NAME.11_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_4_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_5_1',
    'tagName': 'READINGTAG_NAME.11_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_5_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_5_2',
    'tagName': 'READINGTAG_NAME.11_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_5_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_6_1',
    'tagName': 'READINGTAG_NAME.11_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_6_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_6_2',
    'tagName': 'READINGTAG_NAME.11_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_6_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_7_1',
    'tagName': 'READINGTAG_NAME.11_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_7_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_7_2',
    'tagName': 'READINGTAG_NAME.11_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_7_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_8_1',
    'tagName': 'READINGTAG_NAME.11_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_8_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_8_2',
    'tagName': 'READINGTAG_NAME.11_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_8_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_9_1',
    'tagName': 'READINGTAG_NAME.11_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_9_1',
    'grade_classificationId': '11_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 11,
    'grade_readingTagId_classificationId': '11_9_2',
    'tagName': 'READINGTAG_NAME.11_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.11_9_2',
    'grade_classificationId': '11_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_10_1',
    'tagName': 'READINGTAG_NAME.12_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_10_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_10_2',
    'tagName': 'READINGTAG_NAME.12_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_10_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_11_1',
    'tagName': 'READINGTAG_NAME.12_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_11_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_11_2',
    'tagName': 'READINGTAG_NAME.12_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_11_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_12_1',
    'tagName': 'READINGTAG_NAME.12_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_12_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_12_2',
    'tagName': 'READINGTAG_NAME.12_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_12_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_13_1',
    'tagName': 'READINGTAG_NAME.12_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_13_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_13_2',
    'tagName': 'READINGTAG_NAME.12_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_13_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_14_1',
    'tagName': 'READINGTAG_NAME.12_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_14_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_14_2',
    'tagName': 'READINGTAG_NAME.12_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_14_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_1_1',
    'tagName': 'READINGTAG_NAME.12_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_1_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_1_2',
    'tagName': 'READINGTAG_NAME.12_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_1_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_2_1',
    'tagName': 'READINGTAG_NAME.12_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_2_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_2_2',
    'tagName': 'READINGTAG_NAME.12_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_2_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_3_1',
    'tagName': 'READINGTAG_NAME.12_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_3_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_3_2',
    'tagName': 'READINGTAG_NAME.12_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_3_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_4_1',
    'tagName': 'READINGTAG_NAME.12_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_4_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_4_2',
    'tagName': 'READINGTAG_NAME.12_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_4_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_5_1',
    'tagName': 'READINGTAG_NAME.12_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_5_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_5_2',
    'tagName': 'READINGTAG_NAME.12_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_5_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_6_1',
    'tagName': 'READINGTAG_NAME.12_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_6_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_6_2',
    'tagName': 'READINGTAG_NAME.12_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_6_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_7_1',
    'tagName': 'READINGTAG_NAME.12_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_7_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_7_2',
    'tagName': 'READINGTAG_NAME.12_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_7_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_8_1',
    'tagName': 'READINGTAG_NAME.12_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_8_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_8_2',
    'tagName': 'READINGTAG_NAME.12_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_8_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_9_1',
    'tagName': 'READINGTAG_NAME.12_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_9_1',
    'grade_classificationId': '12_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 12,
    'grade_readingTagId_classificationId': '12_9_2',
    'tagName': 'READINGTAG_NAME.12_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.12_9_2',
    'grade_classificationId': '12_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_10_1',
    'tagName': 'READINGTAG_NAME.1_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_10_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_10_2',
    'tagName': 'READINGTAG_NAME.1_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_10_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_11_1',
    'tagName': 'READINGTAG_NAME.1_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_11_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_11_2',
    'tagName': 'READINGTAG_NAME.1_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_11_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_12_1',
    'tagName': 'READINGTAG_NAME.1_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_12_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_12_2',
    'tagName': 'READINGTAG_NAME.1_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_12_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_13_1',
    'tagName': 'READINGTAG_NAME.1_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_13_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_13_2',
    'tagName': 'READINGTAG_NAME.1_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_13_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_14_1',
    'tagName': 'READINGTAG_NAME.1_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_14_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_14_2',
    'tagName': 'READINGTAG_NAME.1_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_14_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_1_1',
    'tagName': 'READINGTAG_NAME.1_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_1_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_1_2',
    'tagName': 'READINGTAG_NAME.1_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_1_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_2_1',
    'tagName': 'READINGTAG_NAME.1_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_2_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_2_2',
    'tagName': 'READINGTAG_NAME.1_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_2_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_3_1',
    'tagName': 'READINGTAG_NAME.1_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_3_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_3_2',
    'tagName': 'READINGTAG_NAME.1_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_3_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_4_1',
    'tagName': 'READINGTAG_NAME.1_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_4_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_4_2',
    'tagName': 'READINGTAG_NAME.1_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_4_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_5_1',
    'tagName': 'READINGTAG_NAME.1_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_5_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_5_2',
    'tagName': 'READINGTAG_NAME.1_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_5_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_6_1',
    'tagName': 'READINGTAG_NAME.1_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_6_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_6_2',
    'tagName': 'READINGTAG_NAME.1_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_6_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_7_1',
    'tagName': 'READINGTAG_NAME.1_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_7_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_7_2',
    'tagName': 'READINGTAG_NAME.1_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_7_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_8_1',
    'tagName': 'READINGTAG_NAME.1_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_8_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_8_2',
    'tagName': 'READINGTAG_NAME.1_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_8_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_9_1',
    'tagName': 'READINGTAG_NAME.1_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_9_1',
    'grade_classificationId': '1_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 1,
    'grade_readingTagId_classificationId': '1_9_2',
    'tagName': 'READINGTAG_NAME.1_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.1_9_2',
    'grade_classificationId': '1_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_10_1',
    'tagName': 'READINGTAG_NAME.2_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_10_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_10_2',
    'tagName': 'READINGTAG_NAME.2_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_10_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_11_1',
    'tagName': 'READINGTAG_NAME.2_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_11_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_11_2',
    'tagName': 'READINGTAG_NAME.2_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_11_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_12_1',
    'tagName': 'READINGTAG_NAME.2_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_12_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_12_2',
    'tagName': 'READINGTAG_NAME.2_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_12_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_13_1',
    'tagName': 'READINGTAG_NAME.2_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_13_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_13_2',
    'tagName': 'READINGTAG_NAME.2_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_13_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_14_1',
    'tagName': 'READINGTAG_NAME.2_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_14_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_14_2',
    'tagName': 'READINGTAG_NAME.2_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_14_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_1_1',
    'tagName': 'READINGTAG_NAME.2_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_1_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_1_2',
    'tagName': 'READINGTAG_NAME.2_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_1_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_2_1',
    'tagName': 'READINGTAG_NAME.2_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_2_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_2_2',
    'tagName': 'READINGTAG_NAME.2_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_2_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_3_1',
    'tagName': 'READINGTAG_NAME.2_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_3_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_3_2',
    'tagName': 'READINGTAG_NAME.2_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_3_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_4_1',
    'tagName': 'READINGTAG_NAME.2_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_4_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_4_2',
    'tagName': 'READINGTAG_NAME.2_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_4_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_5_1',
    'tagName': 'READINGTAG_NAME.2_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_5_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_5_2',
    'tagName': 'READINGTAG_NAME.2_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_5_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_6_1',
    'tagName': 'READINGTAG_NAME.2_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_6_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_6_2',
    'tagName': 'READINGTAG_NAME.2_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_6_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_7_1',
    'tagName': 'READINGTAG_NAME.2_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_7_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_7_2',
    'tagName': 'READINGTAG_NAME.2_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_7_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_8_1',
    'tagName': 'READINGTAG_NAME.2_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_8_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_8_2',
    'tagName': 'READINGTAG_NAME.2_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_8_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_9_1',
    'tagName': 'READINGTAG_NAME.2_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_9_1',
    'grade_classificationId': '2_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 2,
    'grade_readingTagId_classificationId': '2_9_2',
    'tagName': 'READINGTAG_NAME.2_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.2_9_2',
    'grade_classificationId': '2_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_10_1',
    'tagName': 'READINGTAG_NAME.3_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_10_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_10_2',
    'tagName': 'READINGTAG_NAME.3_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_10_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_11_1',
    'tagName': 'READINGTAG_NAME.3_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_11_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_11_2',
    'tagName': 'READINGTAG_NAME.3_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_11_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_12_1',
    'tagName': 'READINGTAG_NAME.3_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_12_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_12_2',
    'tagName': 'READINGTAG_NAME.3_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_12_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_13_1',
    'tagName': 'READINGTAG_NAME.3_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_13_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_13_2',
    'tagName': 'READINGTAG_NAME.3_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_13_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_14_1',
    'tagName': 'READINGTAG_NAME.3_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_14_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_14_2',
    'tagName': 'READINGTAG_NAME.3_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_14_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_1_1',
    'tagName': 'READINGTAG_NAME.3_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_1_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_1_2',
    'tagName': 'READINGTAG_NAME.3_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_1_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_2_1',
    'tagName': 'READINGTAG_NAME.3_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_2_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_2_2',
    'tagName': 'READINGTAG_NAME.3_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_2_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_3_1',
    'tagName': 'READINGTAG_NAME.3_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_3_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_3_2',
    'tagName': 'READINGTAG_NAME.3_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_3_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_4_1',
    'tagName': 'READINGTAG_NAME.3_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_4_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_4_2',
    'tagName': 'READINGTAG_NAME.3_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_4_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_5_1',
    'tagName': 'READINGTAG_NAME.3_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_5_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_5_2',
    'tagName': 'READINGTAG_NAME.3_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_5_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_6_1',
    'tagName': 'READINGTAG_NAME.3_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_6_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_6_2',
    'tagName': 'READINGTAG_NAME.3_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_6_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_7_1',
    'tagName': 'READINGTAG_NAME.3_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_7_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_7_2',
    'tagName': 'READINGTAG_NAME.3_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_7_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_8_1',
    'tagName': 'READINGTAG_NAME.3_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_8_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_8_2',
    'tagName': 'READINGTAG_NAME.3_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_8_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_9_1',
    'tagName': 'READINGTAG_NAME.3_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_9_1',
    'grade_classificationId': '3_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 3,
    'grade_readingTagId_classificationId': '3_9_2',
    'tagName': 'READINGTAG_NAME.3_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.3_9_2',
    'grade_classificationId': '3_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_10_1',
    'tagName': 'READINGTAG_NAME.4_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_10_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_10_2',
    'tagName': 'READINGTAG_NAME.4_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_10_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_11_1',
    'tagName': 'READINGTAG_NAME.4_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_11_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_11_2',
    'tagName': 'READINGTAG_NAME.4_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_11_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_12_1',
    'tagName': 'READINGTAG_NAME.4_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_12_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_12_2',
    'tagName': 'READINGTAG_NAME.4_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_12_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_13_1',
    'tagName': 'READINGTAG_NAME.4_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_13_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_13_2',
    'tagName': 'READINGTAG_NAME.4_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_13_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_14_1',
    'tagName': 'READINGTAG_NAME.4_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_14_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_14_2',
    'tagName': 'READINGTAG_NAME.4_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_14_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_1_1',
    'tagName': 'READINGTAG_NAME.4_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_1_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_1_2',
    'tagName': 'READINGTAG_NAME.4_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_1_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_2_1',
    'tagName': 'READINGTAG_NAME.4_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_2_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_2_2',
    'tagName': 'READINGTAG_NAME.4_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_2_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_3_1',
    'tagName': 'READINGTAG_NAME.4_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_3_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_3_2',
    'tagName': 'READINGTAG_NAME.4_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_3_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_4_1',
    'tagName': 'READINGTAG_NAME.4_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_4_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_4_2',
    'tagName': 'READINGTAG_NAME.4_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_4_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_5_1',
    'tagName': 'READINGTAG_NAME.4_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_5_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_5_2',
    'tagName': 'READINGTAG_NAME.4_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_5_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_6_1',
    'tagName': 'READINGTAG_NAME.4_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_6_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_6_2',
    'tagName': 'READINGTAG_NAME.4_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_6_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_7_1',
    'tagName': 'READINGTAG_NAME.4_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_7_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_7_2',
    'tagName': 'READINGTAG_NAME.4_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_7_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_8_1',
    'tagName': 'READINGTAG_NAME.4_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_8_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_8_2',
    'tagName': 'READINGTAG_NAME.4_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_8_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_9_1',
    'tagName': 'READINGTAG_NAME.4_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_9_1',
    'grade_classificationId': '4_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 4,
    'grade_readingTagId_classificationId': '4_9_2',
    'tagName': 'READINGTAG_NAME.4_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.4_9_2',
    'grade_classificationId': '4_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_10_1',
    'tagName': 'READINGTAG_NAME.5_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_10_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_10_2',
    'tagName': 'READINGTAG_NAME.5_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_10_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_11_1',
    'tagName': 'READINGTAG_NAME.5_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_11_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_11_2',
    'tagName': 'READINGTAG_NAME.5_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_11_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_12_1',
    'tagName': 'READINGTAG_NAME.5_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_12_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_12_2',
    'tagName': 'READINGTAG_NAME.5_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_12_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_13_1',
    'tagName': 'READINGTAG_NAME.5_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_13_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_13_2',
    'tagName': 'READINGTAG_NAME.5_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_13_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_14_1',
    'tagName': 'READINGTAG_NAME.5_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_14_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_14_2',
    'tagName': 'READINGTAG_NAME.5_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_14_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_1_1',
    'tagName': 'READINGTAG_NAME.5_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_1_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_1_2',
    'tagName': 'READINGTAG_NAME.5_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_1_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_2_1',
    'tagName': 'READINGTAG_NAME.5_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_2_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_2_2',
    'tagName': 'READINGTAG_NAME.5_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_2_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_3_1',
    'tagName': 'READINGTAG_NAME.5_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_3_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_3_2',
    'tagName': 'READINGTAG_NAME.5_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_3_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_4_1',
    'tagName': 'READINGTAG_NAME.5_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_4_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_4_2',
    'tagName': 'READINGTAG_NAME.5_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_4_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_5_1',
    'tagName': 'READINGTAG_NAME.5_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_5_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_5_2',
    'tagName': 'READINGTAG_NAME.5_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_5_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_6_1',
    'tagName': 'READINGTAG_NAME.5_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_6_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_6_2',
    'tagName': 'READINGTAG_NAME.5_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_6_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_7_1',
    'tagName': 'READINGTAG_NAME.5_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_7_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_7_2',
    'tagName': 'READINGTAG_NAME.5_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_7_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_8_1',
    'tagName': 'READINGTAG_NAME.5_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_8_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_8_2',
    'tagName': 'READINGTAG_NAME.5_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_8_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_9_1',
    'tagName': 'READINGTAG_NAME.5_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_9_1',
    'grade_classificationId': '5_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 5,
    'grade_readingTagId_classificationId': '5_9_2',
    'tagName': 'READINGTAG_NAME.5_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.5_9_2',
    'grade_classificationId': '5_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_10_1',
    'tagName': 'READINGTAG_NAME.6_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_10_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_10_2',
    'tagName': 'READINGTAG_NAME.6_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_10_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_11_1',
    'tagName': 'READINGTAG_NAME.6_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_11_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_11_2',
    'tagName': 'READINGTAG_NAME.6_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_11_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_12_1',
    'tagName': 'READINGTAG_NAME.6_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_12_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_12_2',
    'tagName': 'READINGTAG_NAME.6_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_12_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_13_1',
    'tagName': 'READINGTAG_NAME.6_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_13_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_13_2',
    'tagName': 'READINGTAG_NAME.6_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_13_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_14_1',
    'tagName': 'READINGTAG_NAME.6_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_14_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_14_2',
    'tagName': 'READINGTAG_NAME.6_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_14_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_1_1',
    'tagName': 'READINGTAG_NAME.6_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_1_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_1_2',
    'tagName': 'READINGTAG_NAME.6_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_1_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_2_1',
    'tagName': 'READINGTAG_NAME.6_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_2_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_2_2',
    'tagName': 'READINGTAG_NAME.6_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_2_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_3_1',
    'tagName': 'READINGTAG_NAME.6_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_3_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_3_2',
    'tagName': 'READINGTAG_NAME.6_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_3_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_4_1',
    'tagName': 'READINGTAG_NAME.6_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_4_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_4_2',
    'tagName': 'READINGTAG_NAME.6_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_4_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_5_1',
    'tagName': 'READINGTAG_NAME.6_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_5_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_5_2',
    'tagName': 'READINGTAG_NAME.6_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_5_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_6_1',
    'tagName': 'READINGTAG_NAME.6_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_6_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_6_2',
    'tagName': 'READINGTAG_NAME.6_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_6_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_7_1',
    'tagName': 'READINGTAG_NAME.6_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_7_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_7_2',
    'tagName': 'READINGTAG_NAME.6_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_7_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_8_1',
    'tagName': 'READINGTAG_NAME.6_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_8_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_8_2',
    'tagName': 'READINGTAG_NAME.6_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_8_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_9_1',
    'tagName': 'READINGTAG_NAME.6_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_9_1',
    'grade_classificationId': '6_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 6,
    'grade_readingTagId_classificationId': '6_9_2',
    'tagName': 'READINGTAG_NAME.6_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.6_9_2',
    'grade_classificationId': '6_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_10_1',
    'tagName': 'READINGTAG_NAME.7_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_10_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_10_2',
    'tagName': 'READINGTAG_NAME.7_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_10_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_11_1',
    'tagName': 'READINGTAG_NAME.7_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_11_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_11_2',
    'tagName': 'READINGTAG_NAME.7_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_11_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_12_1',
    'tagName': 'READINGTAG_NAME.7_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_12_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_12_2',
    'tagName': 'READINGTAG_NAME.7_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_12_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_13_1',
    'tagName': 'READINGTAG_NAME.7_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_13_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_13_2',
    'tagName': 'READINGTAG_NAME.7_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_13_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_14_1',
    'tagName': 'READINGTAG_NAME.7_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_14_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_14_2',
    'tagName': 'READINGTAG_NAME.7_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_14_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_1_1',
    'tagName': 'READINGTAG_NAME.7_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_1_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_1_2',
    'tagName': 'READINGTAG_NAME.7_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_1_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_2_1',
    'tagName': 'READINGTAG_NAME.7_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_2_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_2_2',
    'tagName': 'READINGTAG_NAME.7_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_2_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_3_1',
    'tagName': 'READINGTAG_NAME.7_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_3_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_3_2',
    'tagName': 'READINGTAG_NAME.7_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_3_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_4_1',
    'tagName': 'READINGTAG_NAME.7_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_4_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_4_2',
    'tagName': 'READINGTAG_NAME.7_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_4_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_5_1',
    'tagName': 'READINGTAG_NAME.7_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_5_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_5_2',
    'tagName': 'READINGTAG_NAME.7_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_5_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_6_1',
    'tagName': 'READINGTAG_NAME.7_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_6_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_6_2',
    'tagName': 'READINGTAG_NAME.7_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_6_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_7_1',
    'tagName': 'READINGTAG_NAME.7_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_7_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_7_2',
    'tagName': 'READINGTAG_NAME.7_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_7_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_8_1',
    'tagName': 'READINGTAG_NAME.7_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_8_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_8_2',
    'tagName': 'READINGTAG_NAME.7_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_8_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_9_1',
    'tagName': 'READINGTAG_NAME.7_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_9_1',
    'grade_classificationId': '7_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 7,
    'grade_readingTagId_classificationId': '7_9_2',
    'tagName': 'READINGTAG_NAME.7_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.7_9_2',
    'grade_classificationId': '7_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_10_1',
    'tagName': 'READINGTAG_NAME.8_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_10_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_10_2',
    'tagName': 'READINGTAG_NAME.8_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_10_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_11_1',
    'tagName': 'READINGTAG_NAME.8_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_11_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_11_2',
    'tagName': 'READINGTAG_NAME.8_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_11_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_12_1',
    'tagName': 'READINGTAG_NAME.8_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_12_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_12_2',
    'tagName': 'READINGTAG_NAME.8_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_12_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_13_1',
    'tagName': 'READINGTAG_NAME.8_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_13_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_13_2',
    'tagName': 'READINGTAG_NAME.8_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_13_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_14_1',
    'tagName': 'READINGTAG_NAME.8_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_14_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_14_2',
    'tagName': 'READINGTAG_NAME.8_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_14_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_1_1',
    'tagName': 'READINGTAG_NAME.8_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_1_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_1_2',
    'tagName': 'READINGTAG_NAME.8_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_1_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_2_1',
    'tagName': 'READINGTAG_NAME.8_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_2_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_2_2',
    'tagName': 'READINGTAG_NAME.8_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_2_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_3_1',
    'tagName': 'READINGTAG_NAME.8_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_3_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_3_2',
    'tagName': 'READINGTAG_NAME.8_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_3_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_4_1',
    'tagName': 'READINGTAG_NAME.8_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_4_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_4_2',
    'tagName': 'READINGTAG_NAME.8_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_4_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_5_1',
    'tagName': 'READINGTAG_NAME.8_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_5_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_5_2',
    'tagName': 'READINGTAG_NAME.8_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_5_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_6_1',
    'tagName': 'READINGTAG_NAME.8_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_6_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_6_2',
    'tagName': 'READINGTAG_NAME.8_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_6_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_7_1',
    'tagName': 'READINGTAG_NAME.8_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_7_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_7_2',
    'tagName': 'READINGTAG_NAME.8_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_7_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_8_1',
    'tagName': 'READINGTAG_NAME.8_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_8_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_8_2',
    'tagName': 'READINGTAG_NAME.8_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_8_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_9_1',
    'tagName': 'READINGTAG_NAME.8_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_9_1',
    'grade_classificationId': '8_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 8,
    'grade_readingTagId_classificationId': '8_9_2',
    'tagName': 'READINGTAG_NAME.8_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.8_9_2',
    'grade_classificationId': '8_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_10_1',
    'tagName': 'READINGTAG_NAME.9_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_10_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 10,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_10_2',
    'tagName': 'READINGTAG_NAME.9_10',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_10_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_11_1',
    'tagName': 'READINGTAG_NAME.9_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_11_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 11,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_11_2',
    'tagName': 'READINGTAG_NAME.9_11',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_11_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_12_1',
    'tagName': 'READINGTAG_NAME.9_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_12_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 12,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_12_2',
    'tagName': 'READINGTAG_NAME.9_12',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_12_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_13_1',
    'tagName': 'READINGTAG_NAME.9_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_13_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 13,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_13_2',
    'tagName': 'READINGTAG_NAME.9_13',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_13_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_14_1',
    'tagName': 'READINGTAG_NAME.9_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_14_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 14,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_14_2',
    'tagName': 'READINGTAG_NAME.9_14',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_14_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_1_1',
    'tagName': 'READINGTAG_NAME.9_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_1_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 1,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_1_2',
    'tagName': 'READINGTAG_NAME.9_1',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_1_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_2_1',
    'tagName': 'READINGTAG_NAME.9_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_2_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 2,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_2_2',
    'tagName': 'READINGTAG_NAME.9_2',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_2_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_3_1',
    'tagName': 'READINGTAG_NAME.9_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_3_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 3,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_3_2',
    'tagName': 'READINGTAG_NAME.9_3',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_3_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_4_1',
    'tagName': 'READINGTAG_NAME.9_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_4_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 4,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_4_2',
    'tagName': 'READINGTAG_NAME.9_4',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_4_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_5_1',
    'tagName': 'READINGTAG_NAME.9_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_5_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 5,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_5_2',
    'tagName': 'READINGTAG_NAME.9_5',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_5_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_6_1',
    'tagName': 'READINGTAG_NAME.9_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_6_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 6,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_6_2',
    'tagName': 'READINGTAG_NAME.9_6',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_6_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_7_1',
    'tagName': 'READINGTAG_NAME.9_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_7_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 7,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_7_2',
    'tagName': 'READINGTAG_NAME.9_7',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_7_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_8_1',
    'tagName': 'READINGTAG_NAME.9_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_8_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 8,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_8_2',
    'tagName': 'READINGTAG_NAME.9_8',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_8_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_9_1',
    'tagName': 'READINGTAG_NAME.9_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_9_1',
    'grade_classificationId': '9_1',
    'classificationId': 1
  });
  GradeReadingTags.push({
    'readingTagId': 9,
    'gradeId': 9,
    'grade_readingTagId_classificationId': '9_9_2',
    'tagName': 'READINGTAG_NAME.9_9',
    'gradeSpecificTagDescription': 'RTDESC_GRADE_READINGTAGID_CLASSIFICATIONID.9_9_2',
    'grade_classificationId': '9_2',
    'classificationId': 2
  });

  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '0_1',
    'grade_classificationId_subStandardId': '0_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '0_2',
    'grade_classificationId_subStandardId': '0_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.0_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '10_1',
    'grade_classificationId_subStandardId': '10_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '10_2',
    'grade_classificationId_subStandardId': '10_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.10_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '11_1',
    'grade_classificationId_subStandardId': '11_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '11_2',
    'grade_classificationId_subStandardId': '11_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.11_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '12_1',
    'grade_classificationId_subStandardId': '12_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '12_2',
    'grade_classificationId_subStandardId': '12_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.12_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '1_1',
    'grade_classificationId_subStandardId': '1_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '1_2',
    'grade_classificationId_subStandardId': '1_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.1_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '2_1',
    'grade_classificationId_subStandardId': '2_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '2_2',
    'grade_classificationId_subStandardId': '2_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.2_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '3_1',
    'grade_classificationId_subStandardId': '3_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '3_2',
    'grade_classificationId_subStandardId': '3_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.3_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '4_1',
    'grade_classificationId_subStandardId': '4_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '4_2',
    'grade_classificationId_subStandardId': '4_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.4_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '5_1',
    'grade_classificationId_subStandardId': '5_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '5_2',
    'grade_classificationId_subStandardId': '5_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.5_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '6_1',
    'grade_classificationId_subStandardId': '6_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '6_2',
    'grade_classificationId_subStandardId': '6_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.6_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '7_1',
    'grade_classificationId_subStandardId': '7_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '7_2',
    'grade_classificationId_subStandardId': '7_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.7_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '8_1',
    'grade_classificationId_subStandardId': '8_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '8_2',
    'grade_classificationId_subStandardId': '8_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.8_2_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RI. 1',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_1',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RI. 2',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_2',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RI. 3',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_3',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RI. 4',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_4',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RI. 5',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_5',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RI. 6',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_6',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RI. 7',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_7',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RI. 8',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_8',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RI. 9',
    'grade_classificationId': '9_1',
    'grade_classificationId_subStandardId': '9_1_9',
    'classificationId': 1,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_1_9'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 1,
    'desc': 'RL. 1',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_1',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_1'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 2,
    'desc': 'RL. 2',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_2',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_2'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 3,
    'desc': 'RL. 3',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_3',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_3'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 4,
    'desc': 'RL. 4',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_4',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_4'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 5,
    'desc': 'RL. 5',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_5',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_5'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 6,
    'desc': 'RL. 6',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_6',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_6'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 7,
    'desc': 'RL. 7',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_7',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_7'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 8,
    'desc': 'RL. 8',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_8',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_8'
  });
  CommonCoreGradeSubStandards.push({
    'commonCoreSubStandardId': 9,
    'desc': 'RL. 9',
    'grade_classificationId': '9_2',
    'grade_classificationId_subStandardId': '9_2_9',
    'classificationId': 2,
    'gradeSpecificTagDescription': 'COMMONCORE_DESC_GRADE_CLASSIFICATION_SUBSTANDARDID.9_2_9'
  });

  dbOrTran.table('GradeReadingTag').bulkAdd(GradeReadingTags);
  dbOrTran.table('CommonCoreGradeSubStandard').bulkAdd(CommonCoreGradeSubStandards);
}
