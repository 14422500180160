import {GradeHoursResponse} from "./grade-hours-response";
export class SchoolWeek {
    public schoolAndGrade: string;

    constructor(public schoolId: string, public grade: number, public hours: GradeHoursResponse[]) {
        this.schoolAndGrade = SchoolWeek.getKey(schoolId, grade);
    }

    public static getKey(schoolId: string, grade: number) {
        return `${schoolId}/${grade}`
    }
}
