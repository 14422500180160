import { WordDefinition } from './word-definition';
import { BookLanguage } from '../enums';
import { SynonymByLexicalCategory } from './synonym-by-lexical-category';

export class DefinitionViewModel {
  selectedWord: string;
  pronunciation: string;
  language: BookLanguage;
  definitions: WordDefinition[];
  spanish_translations: string[];
  chinese_translations: string[];
  isixhosa_translations: string[];
  sesotho_translations: string[];
  english_translations: string[];
  examples: string[];
  synonyms: string[];
  synonymsByLexicalCategory?: SynonymByLexicalCategory[];
  originalWord?: string;
  originalLanguage?: BookLanguage;
  originalTranslation?: string;

  constructor(selectedWord: string, language: BookLanguage, originalLanguage?: BookLanguage, originalWord?: string, originalTranslation?: string) {
    this.selectedWord = selectedWord;
    this.language = language;
    this.definitions = [];
    this.chinese_translations = [];
    this.spanish_translations = [];
    this.isixhosa_translations = [];
    this.english_translations = [];
    this.examples = [];
    this.synonyms = [];
    this.synonymsByLexicalCategory = [];
    this.originalLanguage = originalLanguage;
    this.originalWord = originalWord;
    this.originalTranslation = originalTranslation;
  }
}
