import {CachePriority, FileType} from '../enums';
import {GlobalConstants} from '../../globals';
import {NgHttpCachingHeaderModel} from '../../../../projects/mindplay/src/app/core/ng-http-caching/models/ng-http-caching-header.model';
import {HttpContext} from '@angular/common/http';


export class ApiClientOptions {
    //Set whether to wait for Response from API for POST/PUT
    waitForResponse: boolean;
    //Describe how should we treat GET response , save in cache or not , fetch from cache or not
    cacheLevel: CachePriority;
    //Whether AuthCheck validation should be performed or not
    skipAuthCheck: boolean;
    downloadFileType?: FileType;
    //Whether to use book Meta data instead of User Metadata
    useBookMetaData: boolean;
    // Whether the post data should be stored in local file storage after processing
    storeAfterProcessing: boolean;
    environmentRouteURL: string;
    isEventHub: boolean;
    messageType: string;
    schoolIdHeader?: string;
    userGroupOrganizationIdHeader?: string;
    userIdHeader?: string;
    acceptLanguageHeader?: string;
    checkOnline: boolean;
    responseType?: string;
    isFormData?: boolean;
    authKey?: string;
    shardKey?: string;
    cacheMinutes = GlobalConstants.UserCacheFirstTimeout;
    ngHttpCachingHeaders?: NgHttpCachingHeaderModel;
    httpContext?: HttpContext;

    constructor(waitForResponse: boolean = true, cacheLevel: CachePriority = CachePriority.NetworkFirst,
                skipAuthCheck: boolean = false, useBookMetaData: boolean = false, downloadFileType: FileType = null,
                storeAfterProcessing: boolean = false, environmentRouteURL: string = '',
                isEventHub: boolean = false, messageType: string = null,
                schoolIdHeader: string = null, userGroupOrganizationIdHeader: string = null, userIdHeader: string = null,
                acceptLanguageHeader: string = null, checkOnline = false, responseType = null, isFormData = null,
                public muteErrors: boolean = true,
                ngHttpCachingHeaders = null,
                httpContext = null
    ) {
        this.waitForResponse = waitForResponse;
        this.cacheLevel = cacheLevel;
        this.skipAuthCheck = skipAuthCheck;
        this.useBookMetaData = useBookMetaData;
        this.downloadFileType = downloadFileType;
        this.storeAfterProcessing = storeAfterProcessing;
        this.environmentRouteURL = environmentRouteURL;
        this.isEventHub = isEventHub;
        this.messageType = messageType;
        this.schoolIdHeader = schoolIdHeader;
        this.userGroupOrganizationIdHeader = userGroupOrganizationIdHeader;
        this.userIdHeader = userIdHeader;
        this.acceptLanguageHeader = acceptLanguageHeader;
        this.checkOnline = checkOnline;
        this.responseType = responseType;
        this.isFormData = isFormData;
        this.ngHttpCachingHeaders = ngHttpCachingHeaders;
        this.httpContext = httpContext;
    }
}
