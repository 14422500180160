import {ConnectionMode} from "./connection-mode";

export class IndexedDBFactory {
    connectionMode: ConnectionMode;
    indexedDBContext: IDBFactory;

    constructor() {
        this.indexedDBContext = window.indexedDB || (<any>window).mozIndexedDB || (<any>window).webkitIndexedDB || (<any>window).msIndexedDB;
        this.connectionMode = {
            readOnly: "readonly",
            readWrite: "readwrite"
        };
    }
}