import Dexie from 'dexie';

// To modify the schema, add a new entry at the end of the function, following the existing pattern.
// If data migration is also needed, .upgrade(...) can be added too. See the Dexie documentation.

export default function ssdbSchema(db: Dexie) {
  db.version(0.2).stores({
    Class: 'classId,&classId,userGroupId',
    School: 'schoolId,&schoolId,districtId',
    SchoolClasses: '[schoolId+classId],schoolId,&[schoolId+classId]',
    SchoolMetaData: 'schoolId,&schoolId',
    Student: 'studentId,&studentId',
    StudentClass: '[studentId+classId],classId,&[studentId+classId],studentId',
    Teacher: 'teacherId,&teacherId',
    TeacherClass: '[teacherId+classId],classId,&[teacherId+classId],teacherId'
  });
  db.version(0.3).stores({
    SP: 'keyId,&keyId'
  });
  db.version(0.4).stores({
    Class: 'classId,&classId,userGroupId,schoolId',
  });
  db.version(0.5).stores({
    ReadingStat: '[schoolId+academicYearId+fromDate+toDate+studentId+classId],[schoolId+academicYearId+fromDate+toDate]'
  });
}

/*** PLEASE NOTE ***
 * IF YOU ARE COPYING VERSIONS FROM THE SS REPO
 * MAKE SURE YOU DO NOT SKIP ANY
 * OTHERWISE THEY WILL NEVER BE APPLIED
 ***/
