import {Injectable} from '@angular/core';
import {IndexedDBFactory} from './idbfactory';
import {IndexedDBContext} from './idbcontext';
import {ApiQueueModel, BookMetaDataQueueModel} from '../core/models';
import {GlobalTableNames, UserMetadataConstants} from '../globals';
import {BehaviorSubject, EMPTY, from, Observable} from 'rxjs';
import {LoggerService} from '../services/logger.service';
import installDB from '../../db/installDb';
import {filter, first, switchMap} from 'rxjs/operators';
import {isNullOrUndefined} from 'app/shared/utils/global.utils';

function isValidIDBKeyRange(key: any) {
    return !isNullOrUndefined(key) && key !== true && key !== false && (Array.isArray(key) ? key.every((val) => !isNullOrUndefined(val)) : true);
}

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {
    private idbFactory: IndexedDBFactory;
    private idbContext: IndexedDBContext;
    private databaseName = 'LightSailDB';

    private _dbReadyPromise: Promise<void>;

    private connectionProxy$ = new BehaviorSubject<boolean>(false);

    constructor() {
        this.connect();
    }

    private checkConnection() {
        return from(this._dbReadyPromise).pipe(
            switchMap(() => {
               return this.connectionProxy$.asObservable();
            }),
            first(),
            switchMap(isConnected => {
                if (isConnected) {
                    return EMPTY;
                }
                this.connect();
                return this.connectionProxy$.pipe(
                    filter(a => a === true),
                );
            }),
        ).toPromise();
    }

    private connect() {
        this._dbReadyPromise = new Promise<void>(async (resolve, reject) => {
            await installDB();
            this.idbFactory = new IndexedDBFactory();
            this.idbContext = new IndexedDBContext(this.databaseName);
            const idbRequest = this.idbFactory.indexedDBContext.open(this.idbContext.databaseName);

            idbRequest.onerror = (event) => {
                this.idbContext.database = idbRequest.result;
                LoggerService.Log('IndexedDB error: ' + (<any>event.target).errorCode);
                this.connectionProxy$.next(false);
                reject();
            };

            idbRequest.onsuccess = () => {
                if (idbRequest.result.version === 1) {
                    this.deleteDatabase();
                    reject();
                } else {
                    this.idbContext.database = idbRequest.result;
                    this.connectionProxy$.next(true);
                    this.idbContext.database.addEventListener( 'close', () => {
                        this.connectionProxy$.next(false);
                    }, false );
                    resolve();
                }
            };
        });
        return this._dbReadyPromise;
    }

    deleteDatabase() {
        const req = indexedDB.deleteDatabase(this.databaseName);
        req.onsuccess = function () {
            window.location.replace(window.location.href.substr(0,  window.location.href.indexOf('index.html')));
        };
        req.onerror = function () {
            window.location.replace(window.location.href.substr(0,  window.location.href.indexOf('index.html')));
        };
        req.onblocked = function () {
            window.location.replace(window.location.href.substr(0,  window.location.href.indexOf('index.html')));
        };
    }

    deleteTable(tableName: string) {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'deleteTable', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
                const table = transaction.objectStore(tableName);
                const deleteRequest = table.clear();

                transaction.onerror = (event) => {
                    reject(event);
                };
                deleteRequest.onerror = (event) => {
                    reject(event);
                };
                deleteRequest.onsuccess = () => {
                    resolve(true);
                };
            }).catch(error => reject(error));
        });
    }

    /*
     The promise resolves to the key of the newly inserted record.
     (usually a string; but could also be a number)
     */
    insert(tableName: string, record: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'insert', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
                const table = transaction.objectStore(tableName);
                const insertRequest = table.put(record);

                transaction.onerror = (event) => {
                    reject('IndexedDB error: Insert in ' + tableName + ' Trace:' + event);
                };

                insertRequest.onerror = (event) => {
                    reject(event);
                };
                insertRequest.onsuccess = () => {
                    resolve(insertRequest.result);
                };
            }).catch(error => reject(error));
        });
    }

    insertMultiple(tableName: string, records: any[]): Promise<any> {
      return new Promise<any>((resolve, reject) => {
        this.checkConnection().then(() => {
          this.idbContext.validate(tableName, 'insert', reject);
          const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
          const table = transaction.objectStore(tableName);
          for(let record of records){
            const request = table.put(record);

            request.onsuccess = ()=> {
              console.log(`New record added`);
            }

            request.onerror = (err)=> {
              console.error(`Error to add new record: ${err}`)
            }
          }

          transaction.onerror = (event) => {
            reject('IndexedDB error: Insert in ' + tableName + ' Trace:' + event);
          };

          transaction.oncomplete = (event) => {
            resolve(event);
          };
        }).catch(error => reject(error));
      });
    }

    update(tableName: string, record: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'update', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
                const table = transaction.objectStore(tableName);
                const updateRequest = table.put(record);

                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                    resolve(record);
                };
                updateRequest.onerror = (event) => {
                    reject(event);
                };
                updateRequest.onsuccess = () => {
                    resolve(updateRequest.result);
                };
            }).catch(error => reject(error));
        });
    }

    deleteByKey(tableName: string, key: number | string | Date): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'deleteByKey', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
                const table = transaction.objectStore(tableName);
                const deleteRequest = table.delete(key);

                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                };
                deleteRequest.onerror = (event) => {
                    reject(event);
                };
                deleteRequest.onsuccess = () => {
                    resolve(true);
                };
            }).catch(error => reject(error));
        });
    }

    deleteByIndex(tableName: string, indexName: string, indexKey: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'deleteByIndex', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
                const table = transaction.objectStore(tableName);
                const index = table.index(indexName);
                const deleteRequest = index.openCursor(IDBKeyRange.only(indexKey));

                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                };
                deleteRequest.onerror = (event) => {
                    reject(event);
                };
                deleteRequest.onsuccess = () => {
                    const cursor = (<any>event.target).result;
                    if (cursor) {
                        cursor.delete();
                        cursor.continue();
                    } else {
                        resolve(true);
                    }
                };
            }).catch(error => reject(error));
        });
    }

    getByKey(tableName: string, key: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getByKey', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readOnly);
                const table = transaction.objectStore(tableName);
                if (key === null || key === undefined) {
                    reject();
                } else {
                    const getRequest = table.get(key);

                    getRequest.onsuccess = (event) => {
                        resolve((<IDBOpenDBRequest>event.target).result);
                    };
                    getRequest.onerror = (event) => {
                        reject(event);
                    };
                    transaction.onerror = (event) => {
                        reject('IndexedDB error: getByKey in ' + tableName + ' Trace:' + event);
                    };
                    transaction.oncomplete = () => {
                    };
                }
            }).catch(error => reject(error));
        });
    }

    async AppendWithMaxRows(tableName: string, record: any, maxRows: number, indexName: string = ''): Promise<any> {
        const count = await this.getCount(tableName);
        if (count >= maxRows) {
            await this.deleteFirst(tableName, indexName);
        }
        await this.insert(tableName, record);
    }

    deleteFirst(tableName: string, indexName: string = ''): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.checkConnection().then(() => {
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
                const table = transaction.objectStore(tableName);
                let deleteRequest: IDBRequest;
                if (indexName === '') {
                    deleteRequest = table.openCursor();
                } else {
                    const index = table.index(indexName);
                    deleteRequest = index.openCursor();
                }
                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                };
                deleteRequest.onerror = (event) => {
                    reject(event);
                };
                deleteRequest.onsuccess = () => {
                    const cursor = (<any>event.target).result;
                    if (cursor) {
                        cursor.delete();
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                };
            }).catch(error => reject(error));
        });
    }

    // returns the first key in the specified range (ONLY USES THE PRIMARY KEY OF THE TABLE).
    getKey(tableName: string, keyRange: IDBKeyRange) {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getKey', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readOnly);
                const table = transaction.objectStore(tableName);
                const getRequest = table.getKey(keyRange);

                getRequest.onsuccess = (event) => {
                    resolve((<IDBOpenDBRequest>event.target).result);
                };
                getRequest.onerror = (event) => {
                    reject(event);
                };
                transaction.onerror = (event) => {
                    reject('IndexedDB error: getKey in ' + tableName + ' Trace:' + event);
                };
                transaction.oncomplete = () => {
                };
            }).catch(error => reject(error));
        });
    }

    // Returns a collection of objects
    getByIndex<T = any>(tableName: string, indexName: string, indexKey: any): Promise<T> {
        if (!isValidIDBKeyRange(indexKey)) {
            return;
        }
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getByIndex', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readOnly);
                const table = transaction.objectStore(tableName);
                const index = table.index(indexName);
                const multiEntry = index.multiEntry;
                const results = [];

                if (multiEntry) {
                    const query = index.openCursor(IDBKeyRange.only(indexKey));
                    query.onsuccess = (event) => {
                        const cursor = (<any>event.target).result;
                        if (!cursor) { return; }
                        results.push(cursor.value);
                        cursor.continue();
                    };
                } else {
                    const getRequest = index.getAll(IDBKeyRange.only(indexKey));
                    let result: any[] = [];
                    getRequest.onsuccess = (event) => {
                        result = (<any>event.target).result;
                        resolve(result);
                    };
                }

                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                    if (results) { resolve(results); }
                };
            }).catch(error => reject(error));
        });
    }

    getByIndexAllKeys(tableName: string, indexName: string, indexKeys: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getByIndexAllKeys', reject);
                const getPromise: Promise<any>[] = [];
                if (Array.isArray(indexKeys)) {
                    for (let i = 0, len = indexKeys.length; i < len; i++) {
                        getPromise.push(this.getByIndex(tableName, indexName, indexKeys[i]));
                    }
                } else {
                    getPromise.push(this.getByIndex(tableName, indexName, indexKeys));
                }

                Promise.all(getPromise).then(function (resultData) {
                    let result = [];
                    if (Array.isArray(resultData[0])) {
                        for (let i = 0, len = resultData.length; i < len; i++) {
                            for (let j = 0, len2 = resultData[i].length; j < len2; j++) {
                                result.push(resultData[i][j]);
                            }
                        }
                    } else {
                        result = resultData;
                    }
                    resolve(result);
                }).catch(error => {
                    reject(error);
                });
            }).catch(error => reject(error));
        });
    }

    getAll(tableName: string): Promise<any[]> {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getAll', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readOnly);
                const table = transaction.objectStore(tableName);
                const getRequest = table.getAll();

                getRequest.onsuccess = (event) => {
                    resolve((<IDBOpenDBRequest>event.target).result);
                };

                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                };
            }).catch(error => reject(error));
        });
    }

    getCount(tableName: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getCount', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readOnly);
                const table = transaction.objectStore(tableName);
                const countRequest = table.count();
                countRequest.onsuccess = (event) => {
                    resolve((<IDBOpenDBRequest>event.target).result);
                };

                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                };
            }).catch(error => reject(error));
        });
    }

    getCountByIndex(tableName: string, indexName: string, indexKey: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getCountByIndex', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readOnly);
                const table = transaction.objectStore(tableName);
                const index = table.index(indexName);
                const countRequest = index.count(IDBKeyRange.only(indexKey));
                countRequest.onsuccess = (event) => {
                    const count = (<IDBOpenDBRequest>event.target).result;
                    resolve(count);
                };

                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                };
            }).catch(error => reject(error));
        });
    }

    getCurrentApiQueue(cleanProcessedEntries: boolean = true): Observable<ApiQueueModel> {
        const tableName: string = GlobalTableNames.ApiQueue;
        return new Observable<ApiQueueModel>((observer) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getCurrentApiQueue', observer.error);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
                const table = transaction.objectStore(tableName);
                const countRequest = table.openCursor();
                countRequest.onsuccess = (event) => {
                    const cursor = (<any>event.target).result;
                    if (cursor) {
                        const thisRow: ApiQueueModel = cursor.value;
                        if (thisRow.processInd === 1) {
                            if (cleanProcessedEntries) {
                                cursor.delete();
                            }
                        } else {
                            observer.next(thisRow);
                        }
                        cursor.continue();
                    } else {
                        observer.next(null);
                    }
                };

                transaction.onerror = (event) => {
                    observer.error(event);
                };
                transaction.oncomplete = () => {
                };
            }).catch(error => observer.error(error));
        });
    }

    getNextBookQueueCall(tableName: string, cleanProcessedEntries: boolean): Promise<BookMetaDataQueueModel> {
        const indexName: string = UserMetadataConstants.QueuePriority;
        return new Promise<BookMetaDataQueueModel>((resolve, reject) => {
            this.checkConnection().then(() => {
                this.idbContext.validate(tableName, 'getNextBookQueueCall', reject);
                const transaction = this.idbContext.database.transaction(tableName, this.idbFactory.connectionMode.readWrite);
                const table = transaction.objectStore(tableName);
                const index = table.index(indexName);
                const getRequest = index.openCursor();
                let result: BookMetaDataQueueModel = null;
                getRequest.onsuccess = (event) => {
                    const cursor = (<any>event.target).result;
                    if (cursor) {
                        const thisRow: BookMetaDataQueueModel = cursor.value;
                        if (thisRow.processInd === 1) {
                            if (cleanProcessedEntries) {
                                cursor.delete();
                            }
                            cursor.continue();
                        } else {
                            result = thisRow;
                            resolve(result);
                        }
                    } else {
                        resolve(result);
                    }
                };

                transaction.onerror = (event) => {
                    reject(event);
                };
                transaction.oncomplete = () => {
                };
            }).catch(error => reject(error));
        });
    }
}
