import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {CachePriority, HttpAction} from './enums';
import {ApiClientOptions, ApiQueueModel} from './models';
import {AppStateConstants, ErrorConstants, GlobalConstants} from '../globals';
import {AppStateService} from '../app.service';
import {ApiQueueService} from './api-queue.service';
import {ApiHttpService} from './api-http.service';
import {UserMetadataService} from './user-metadata.service';
import {BookMetadataService} from './book-metadata.service';
import {StaticAuthService} from '../auth/static-auth.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SessionStorageService} from '../localstorage/session-storage.service';
import {LoggerService} from 'app/services/logger.service';

@Injectable()
export class ApiClientService {

    constructor(private _http: HttpClient,
                private _apiQueue: ApiQueueService,
        private _apiCaller: ApiHttpService,
        private _userMeta: UserMetadataService,
        private _bookMeta: BookMetadataService,
        private _appState: AppStateService) {
    }

    // Rewritten GET handling code
    async Get<T>(path: string, options: ApiClientOptions = new ApiClientOptions()): Promise<T> {
        const {cacheLevel} = options;
        const callerInfo = new Error();
        // Init cache service
        // const cacheService = this._userMeta;
        const cacheService = options.useBookMetaData ? this._bookMeta : this._userMeta;

        // Set the cache promises in motion. We might or might not pick them up later
        let cachedValuePromise, staleCacheValuePromise;
        if (cacheLevel !== CachePriority.NoCache) {
            cachedValuePromise = (cacheService.get as any)(path, true);
            staleCacheValuePromise = (cacheService.get as any)(path, false);
        }

        // Return from cache if it's a type that supports it
        if (cacheLevel === CachePriority.CacheOnly || cacheLevel === CachePriority.CacheFirst) {
            try {
                const cachedValue = await cachedValuePromise;
                if (cachedValue) {
                    return cachedValue;
                }
            } catch (e) {}
        }

        // Cache didn't satisfy the request

        // If CacheOnly, return from the stale cache whether or not there's something there
        if (cacheLevel === CachePriority.CacheOnly) {
            return staleCacheValuePromise;
        }

        // Try network
        try {
            const networkResponse = await this.getFromNetwork<T>(path, options);
            // Put it in the cache for next time
            if (networkResponse && cacheLevel !== CachePriority.NoCache) {
                cacheService.save(path, networkResponse, null, options.cacheMinutes).catch();
            }
            return networkResponse;
        } catch (e) {
            // Network error. If we're not NoCache, and it is in the stale cache, return it
            if (cacheLevel !== CachePriority.NoCache) {
                const staleCacheValue = await staleCacheValuePromise;
                if (staleCacheValue) {
                    return staleCacheValue;
                }
            }
            if (!options.muteErrors) {
                LoggerService.Error('Upon calling API an error occurred', callerInfo);
            }

            // Stale cache couldn't satisfy. Re-throw the network error
            throw e;
        }
    }

    private async getFromNetwork<T>(path: string, options: ApiClientOptions): Promise<T> {
        // Check if online
        if (!options.checkOnline && !this._appState.strictGet(AppStateConstants.connected, true)) {
            throw new Error('offline');
        }

        const serverUrl = options.environmentRouteURL || this._appState.get(AppStateConstants.lightSailServerURL);
        const url = new URL(path, serverUrl);

        // Headers
        let headers = new HttpHeaders();
        const shardKey = options.shardKey || SessionStorageService.Get(GlobalConstants.ShardKey);
        if (shardKey) {
            headers = headers.set('ShardKey', shardKey);
        }
        const authKey = options.authKey ||SessionStorageService.Get(GlobalConstants.AuthKey);
        if (authKey) {
            headers = headers.set('Authorization', `bearer ${authKey}`);
        }

        if (options.ngHttpCachingHeaders) {
          headers = Object.keys(options.ngHttpCachingHeaders)
            .reduce((acc, header) => acc.append(header, options.ngHttpCachingHeaders[header]), headers)
        }

        const headerOptions = {
            schoolIdHeader: 'SchoolId',
            userGroupOrganizationIdHeader: 'UserGroupOrganizationId',
            userIdHeader: 'UserId',
            acceptLanguageHeader: 'Accept-Language',
            identityToken: 'IdentityToken',
        };
        headers = Object.entries(headerOptions).reduce(
            (currentHeaders, [optName, headerName]) => options[optName] ? currentHeaders.set(headerName, options[optName]) : currentHeaders,
            headers
        );

        const httpOptions = {headers, context: options.httpContext};
        if (options.responseType) {
            (<any>httpOptions).responseType = options.responseType as 'json';
        }

        // Do the request
        return this._http.get<T>(url.href, httpOptions).toPromise();
    }

    async Invoke<T>(path: string, action: HttpAction, content?: any, options: ApiClientOptions = new ApiClientOptions()): Promise<T> {
        return await this.InvokeApi<T>(path, action, content, options).toPromise();
    }

    InvokeApi<T>(path: string, action: HttpAction, content?: any, options: ApiClientOptions = new ApiClientOptions()): Observable<T> {
        const callerInfo = new Error();
        if (action === HttpAction.Get) {
            return from<Promise<T>>(this.Get<T>(path, options));
        } else {
            const serverUrl = options.environmentRouteURL || this._appState.get(AppStateConstants.lightSailServerURL);
            if (options.waitForResponse) {
                if (StaticAuthService.IsAuthenticated() || options.skipAuthCheck) {
                    const params: ApiQueueModel = new ApiQueueModel();
                    params.serverUrl = serverUrl;
                    params.path = path;
                    params.action = action.toString();
                    params.content = content;
                    params.downloadFileType = options.downloadFileType;
                    params.isEventHub = options.isEventHub;
                    params.messageType = options.messageType;
                    params.schoolIdHeader = options.schoolIdHeader;
                    params.userGroupOrganizationIdHeader = options.userGroupOrganizationIdHeader;
                    params.userIdHeader = options.userIdHeader;
                    params.isFormData = options.isFormData;
                    params.skipAuthCheck = options.skipAuthCheck;
                    params.authKey = options.authKey;
                    params.shardKey = options.shardKey;
                    params.ngHttpCachingHeaders = options.ngHttpCachingHeaders;
                    params.httpContext = options.httpContext
                    return this._apiCaller.callApi<T>(params);
                } else {
                    return new Observable<T>((observer) => {
                        observer.error(ErrorConstants.NotAuthenticated);
                    });
                }
            } else {
                return new Observable<T>((observer) => {
                    this._apiQueue.addNonGetCall<T>(serverUrl, path, action, content, options).then(() => {
                        observer.next();
                        observer.complete();
                    }).catch(error => {
                        if (!options.muteErrors) {
                            LoggerService.Error('Upon calling API an error occurred', callerInfo);
                        }
                        observer.error(error);
                    });
                });
            }
        }
    }

    createURLSearchParams(query: any, defaults: any = {}): string {
        const values = Object.assign(defaults || {});
        Object.keys(query || {})
            .filter(k => (query[k] !== null && query[k] !== '' && query[k] !== undefined))
            .map(k => values[k] = query[k]);
        const url = new URLSearchParams(values).toString();
        return (url && '?') + url;
    }

    static CreateURLSearchParams(query: any, defaults: any = {}): string {
        const values = Object.assign(defaults || {});
        Object.keys(query || {})
            .filter(k => (query[k] !== null && query[k] !== '' && query[k] !== undefined))
            .map(k => values[k] = query[k]);
        const url = new URLSearchParams(values).toString();
        return (url && '?') + url;
    }
}

