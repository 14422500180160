import {FileType} from '../enums';
import {NgHttpCachingHeaderModel} from '../../../../projects/mindplay/src/app/core/ng-http-caching/models/ng-http-caching-header.model';
import {HttpContext} from '@angular/common/http';

export class ApiQueueModel {
  queueId: number;
  serverUrl: string;
  path: string;
  action: string;
  content: any;
  processInd: number;
  downloadFileType?: FileType;
  storeAfterProcessing: boolean;
  isEventHub: boolean;
  messageType: string;
  schoolIdHeader?: string;
  userGroupOrganizationIdHeader?: string;
  userIdHeader?: string;
  skipAuthCheck?: boolean;
  isFormData?: boolean;
  authKey?: string;
  shardKey?: string;
  ngHttpCachingHeaders?: NgHttpCachingHeaderModel;
  httpContext?: HttpContext;
}
