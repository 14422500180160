import {ApiQueueModel} from "./api-queue.model";

export class ApiLogModel extends ApiQueueModel {
    sentDate: Date;
    successInd: boolean;
    failureReason: string;

    constructor (apiModel: ApiQueueModel) {
        super();
        this.queueId = apiModel.queueId;
        this.serverUrl = apiModel.serverUrl;
        this.path = apiModel.path;
        this.action = apiModel.action;
        this.content = apiModel.content;
        this.processInd = apiModel.processInd;
        this.downloadFileType = apiModel.downloadFileType;
        this.storeAfterProcessing = apiModel.storeAfterProcessing;
        this.isEventHub = apiModel.isEventHub;
        this.messageType = apiModel.messageType;
    }
}

