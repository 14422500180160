import {environment} from "../../environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

    static customLog(functionName: string, content: string) {
        if (environment.loggerActive) {
            console.log(functionName + content, new Date());
        }
    }

    static Log(message: any) {
        if (environment.debugLogActive) {
            console.log(message, new Date());
        }
    }

    static Error(message: string, error: any = null) {
        if (environment.errorLogActive) {
            if (error != null) {
                console.log(message, error);
            } else {
                console.log(message);
            }
        }
    }
}
