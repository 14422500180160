import {Injectable, OnDestroy} from '@angular/core';
import {isNullOrUndefined} from '../../src/app/shared/utils/global.utils';
import {AppStateConstants, Dictionary, DictionaryInterface, GlobalConstants} from './globals';
import {environment} from '../environments/environment';
import {BookAssesmentRefresh} from './models';
import {SessionStorageService} from './localstorage/session-storage.service';
import {LsCommonService} from './shared/ls-common.service';
import {SwUpdate} from '@angular/service-worker';
import {Subscription} from 'rxjs';
import defaultDetector, {addListener, checkers, launch} from 'devtools-detector';

export interface InternalStateType {
    [key: string]: any;
}

@Injectable()
export class AppStateService implements OnDestroy {
    _state: InternalStateType = {};
    portfolioRefreshBooks: DictionaryInterface<BookAssesmentRefresh> = new Dictionary<BookAssesmentRefresh>();
    private _subscriptions: Subscription[] = [];
    private _swUpdateAvailable = false;
    private _swUpdateReady = false;


    public get UpdateReady(): boolean {
        return this._swUpdateReady;
    }

    constructor(
        private _swUpdate: SwUpdate
    ) {
        // Check for SW updates but don't do anything about it yet
        this._swUpdate.versionUpdates.subscribe((event) => {
          if (event?.type === 'VERSION_READY') {
            this._swUpdateAvailable = true
          }
        });
        this.updateCheck();
        setInterval(() => { this.updateCheck(); }, 5 * 60 * 1000);

        new Promise((resolve, reject) => {
            if (!environment.checkExtraConnected) {
                return resolve(false);
            }
            const checkStr = LsCommonService.getCookie('_c');
            if (!checkStr) {
                return resolve(true);
            }
            const data = new TextEncoder().encode(checkStr + GlobalConstants.AdminType + GlobalConstants.cryptoHashBT);
            return window.crypto.subtle.digest('SHA-256', data)
                .then(result => {
                    const byteArray: any = new Uint8Array(result);
                    const hash = btoa(String.fromCharCode(...byteArray));
                    resolve(hash !== GlobalConstants.debugOK);
                }, () => resolve(true));
        })
            .then(checkExtraConnected => {
                if (location.origin === 'http://localhost:4200') {
                    return; // it's a local development
                }
                this.set(AppStateConstants.extraConnected, checkExtraConnected);

                if (window.navigator.vendor.indexOf('Google') !== -1) {
                  if (checkExtraConnected) {
                    setInterval(() => {
                      if (sessionStorage.getItem('__dt_flag') === '1') {
                        sessionStorage.removeItem('__dt_flag');
                        window.location.href = 'resetApp.html?cb=' + new Date().valueOf() + '&upgradebecause=webdev';
                      }
                    }, 1000);
                  }
                } else {
                  //@ts-ignore
                  defaultDetector._checkers = [
                    checkers.depRegToStringChecker,
                    checkers.elementIdChecker,
                    checkers.regToStringChecker,
                    checkers.debuggerChecker,
                  ];
                  addListener((isOpen) => {
                    if (isOpen) {
                      window.location.href = 'resetApp.html?cb=' + new Date().valueOf() + '&upgradebecause=webdev';
                    }
                  });

                  if (checkExtraConnected) {
                    launch();
                  }
                }
            });

        // Detect Google Translate
        new MutationObserver(mutations => mutations.forEach(mutation => {
            if (/\btranslated-/.test((mutation.target as HTMLHtmlElement).className) && window['google']) {
                // Page has been translated
                SessionStorageService.Delete('auth_key');
                SessionStorageService.Delete('ShardKey');
                window.location.href = 'resetApp.html?cb=' + new Date().valueOf() + '&upgradebecause=gtranslate';
            }
        })).observe(document.documentElement, {attributes: true, attributeFilter: ['class']});

        if (isNullOrUndefined(this._state[AppStateConstants.lightSailServerURL])) {
            this.set(AppStateConstants.lightSailServerURL,
                (!environment.forceApiURL) ? window.location.origin + '/' : environment.lightSailURL);
        }

        if (isNullOrUndefined(this._state[AppStateConstants.rocketChatApiUrl])) {
            this.set(AppStateConstants.rocketChatApiUrl, environment.rocketChatApiUrl);
        }

        if (isNullOrUndefined(this._state[AppStateConstants.chinaRegion])) {
            this.set(AppStateConstants.chinaRegion, environment.chinaRegion || window.location.href.includes('lightsailed.cn'));
        }
        if (isNullOrUndefined(this._state[AppStateConstants.awsTranscribeAccessId]) ||
            isNullOrUndefined(this._state[AppStateConstants.awsTranscribeSecretKey])
        ) {
            this.set(AppStateConstants.awsTranscribeAccessId, environment.awsTranscribeAccessId);
            this.set(AppStateConstants.awsTranscribeSecretKey, environment.awsTranscribeSecretKey);
            this.set(AppStateConstants.awsRegion, environment.awsRegion);
        }
        // ok so we're starting up - remove the redirect flag if it's there.
        SessionStorageService.Delete(GlobalConstants.SessionRedirectingSS);
    }

    private async updateCheck() {
        try {
          if (this._swUpdate.isEnabled) {
            const isNewVersionAvailable = await this._swUpdate.checkForUpdate();
            if (isNewVersionAvailable || this._swUpdateAvailable) {
              this._swUpdateReady = true;
            }
          }
        } catch (e) {
          console.error('Failed to check for update:', e)
        }
    }

    async triggerUpdate() {
      try {
        const updated = await this._swUpdate.activateUpdate();
        if (updated) {
          document.location.reload();
        }
      } catch (e) {
        console.error('Failed to apply updates:', e)
      }
    }

    // already return a clone of the current state
    get state() {
        return this._state = this._clone(this._state);
    }

    // never allow mutation
    set state(value) {
        throw new Error('do not mutate the `.state` directly');
    }

    static toBoolean(value): boolean {
        switch (value) {
            case true:
            case 'true':
            case 1:
            case '1':
            case 'on':
            case 'yes':
                return true;
            default:
                return false;
        }
    }

    getBool(prop?: any, defaultValue?: any): boolean {
        return AppStateService.toBoolean(this.strictGet(prop, defaultValue));
    }

    // Usage: let result = strictGet(AppStateConstants.xxx[, default]);
    //  Will return default if there is no appState value corresponding to AppStateConstants.xxx
    //  if default is not specified, then null will be used as the default.
    strictGet<T = any>(prop?: any, defaultValue?: any): T {
        if (defaultValue === undefined) {
            defaultValue = null;
        }
        // use our state getter for the clone
        const state = this.state;
        return state.hasOwnProperty(prop) ? state[prop] : defaultValue;
    }

    get(prop?: any) {
        // use our state getter for the clone
        const state = this.state;
        return state.hasOwnProperty(prop) ? state[prop] : state;
    }

    set(prop: string, value: any) {
        // TODO: SW: do we still want to make sure that there's a serviceWorker...?
        //      if so - then how?
        // this._confirmSW();
        // internally mutate our state
        return this._state[prop] = value;
    }

    hasProp(prop: string): boolean {
        const state = this.state;
        return state.hasOwnProperty(prop);
    }

    AddBookRefresh(bookId: string): void {
        this.portfolioRefreshBooks.add(bookId, new BookAssesmentRefresh());
    }

    ngOnDestroy(): void {
        if (this._subscriptions.length > 0) {
            this._subscriptions.forEach(subscrip => {
                if (subscrip) {
                    subscrip.unsubscribe();
                }
            });
            this._subscriptions = [];
        }
    }

    private _clone(object: InternalStateType) {
        // simple object clone
        return JSON.parse(JSON.stringify(object));
    }
}
