import {AssessmentType} from '../enums';
import {AssessmentReviewStatus} from '../enums/assessment-review-status.enum';
import {AssessmentStatus} from '../enums/assessment-status.enum';
import {ReadingTagId} from '../enums/reading-tag-id';
import { TypeOfPhonology } from '../enums/type-of-phonology.enum';
import {AssessmentQuestionResponse} from './assessment-question-response';
import {AssessmentMetadata} from './assessment-metadata';
import { TaggingModel } from './tagging-model';

export class AssessmentDetailResponse<T = string> {
  assessmentId: string;//GUID
  assessmentTypeId: AssessmentType; //bit
  assessmentStatusId: AssessmentStatus; //bit
  commonCoreStandardId: number; //bit
  commonCoreStandardDescription: string;
  commonCoreSubStandardId: number; //bit
  commonCoreSubStandardDescription: string;
  anchorStandardDescription: string;
  assessmentQuestions: AssessmentQuestionResponse<T>[];
  bookId: string; //GUID
  name: string;
  pageNumber: number;
  startLocation: string;
  endLocation: string;
  createDate: string; //Date
  canPageBack: boolean;
  metadata: string;
  eBookFormatTypeId: number;
  readingTagId: ReadingTagId;
  isNewTaggingVersion: boolean;
  userId?: string;
  custom: boolean;
  inline: boolean;
  assignmentId: string;
  readingStandardStrandId?: string;
  selectedText?: string;
  reviewStatus?: AssessmentReviewStatus;
  isCommunityQuestion?: boolean;
  communityAssessmentId?: string;
  isActive?: boolean;
  assessmentMetadata?: AssessmentMetadata;
  phonologyStandardId: TypeOfPhonology;
  taggingModel?: TaggingModel;
  locationPercentage?: number;
}
