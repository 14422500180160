import {Injectable} from '@angular/core';
import {GlobalConstants} from '../globals';
import {SessionStorageService} from '../localstorage/session-storage.service';
import { isNullOrUndefined } from 'app/shared/utils/global.utils';

@Injectable()
export class StaticAuthService {
    constructor() {
    }

    static IsAuthenticated(): boolean {
        let authKey: string = SessionStorageService.Get(GlobalConstants.AuthKey);
        if (authKey === 'undefined' || authKey === null) {
            authKey = '';
        }
        return authKey.length > 0;

    }

    static IsLogged(): boolean {
        let userId: string = SessionStorageService.Get(GlobalConstants.UserId);
        let referralAffiliateId: string = SessionStorageService.Get(GlobalConstants.ReferralAffiliateId);
        if (isNullOrUndefined(userId)) {
            userId = '';
        }
        if (isNullOrUndefined(referralAffiliateId)) {
          referralAffiliateId = '';
        }

        return userId.length > 0 || referralAffiliateId.length > 0;

    }

    static setPath(path) {
        SessionStorageService.Save('path', '/' + path);
    }

    static getPath() {
        return SessionStorageService.Get('path');
    }

    static clearPath() {
        SessionStorageService.Delete('path');
    }

}
