import {PageSessionSource} from '../enums/page-session-source.enum';

export class PageSessionProgress {
    pageStart: number;
    pageEnd: number;
    locationStart: string;
    locationEnd: string;
    wordCount: number;
    bookClosing: boolean;
    source: PageSessionSource;
    sectionIndex: number;
}
