import {StoreDevtoolsModule} from '@ngrx/store-devtools';

export const environment = {
  production: false,
  lightSailURL: 'https://reader.lightsailed.com/',
  // lightSailURL: 'https://reader.lightsailed.cn/',
  // lightSailURL: 'https://sales.lightsailed.com/',
  // lightSailURL: 'https://qa-hot.ls-api.com/',
  development: true,
  checkExtraConnected: true,
  forceApiURL: true,
  //To avoid CORS issue, always use redirect urls without port number
  cleverClientId: '', // set in web-client.service.ts
  cleverClientSecret: 'ccf86b670a0b83da1a67bf931d9d8ef4ffe756dc', // Move to API
  canResetServer: false, // REMOVE
  loggerActive: true,
  debugLogActive: false,
  errorLogActive: true,
  preCacheData: false, // REMOVE
  encryptDownloads: false, // REMOVE
  decryptBooks: false, // REMOVE
  SSOProvidersAvailable: true, // Move to API
  canSwitchLanguage: true, // REMOVE after checking with AB
  encryptSensitives: true,
  chinaRegion: false, // Move to API
  // Move following 3 to global-constants
  googleClientId: '39302110932-q391adt1q1p45vqdpfu5se5em4murm0b.apps.googleusercontent.com',
  googleSheetsDevKey: 'AIzaSyCyLx2XXE2Gj2JikJ2M7X0Q88JyQt4D4sI',
  googleAppId: '39302110932',
  canExportWithGoogle: false, // Move to API
  showAssignments: true, // REMOVE
  useLiveChat: false, // Move to API
  showRosenQAFilter: false, // Research needed
  hmr: false,
  // PROD
  rocketChatApiUrl: 'https://chat.lightsailed.com',
  rtmpEndPoint: 'https://signalr-prod-dc1.lightsailed.com/',
  // CHINA
  // rocketChatApiUrl: 'https://chat.lightsailed.cn',
  // rtmpEndPoint: 'https://signalr.lightsailed.cn/',
  // SALES
  // rocketChatApiUrl: 'https://chat-demo.ls-api.com',
  // rtmpEndPoint: 'https://signalr-demo.ls-api.com/',
  // QA-HOT
  // rocketChatApiUrl: 'https://chat-qa-hot.ls-api.com',
  // rtmpEndPoint: 'https://signalr-qahot2.ls-api.com/',
  captchaKey: '6LfEVvIcAAAAAIt71WPG_QBN8doEE0pcO_95rPTb',
  captchaKeyMudita: '6LfDWfIcAAAAAH1WaEYfsnDWUeuJQUrGEJ24hj9q',
  awsTranscribeAccessId: 'AKIAXQEK7G7RS2N3O6CR',
  awsTranscribeSecretKey: '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',
  awsRegion: 'us-east-1',
  devModules: [
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: true,
      actionsBlocklist: [
        'Common Chat',
        'School Chat',
        'Consumer Chat'
      ]
    }),
  ],
  consumerRegistrationUrl: 'https://lightsailed.com/homeschool/registration/',
  googleMapsApiKey: 'AIzaSyAdg-bL3yneRMDCovXImPcng0V8-8NxUHI',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
