import {LoginDetail} from "./login-detail";
import {CSVHeader} from "../component-modal/csv-view/csv-header";
export class ClassLogins {
    loginDetails:LoginDetail[];
    headers: CSVHeader[] ;

    constructor(logins: LoginDetail[]) {
    this.loginDetails = logins;
    this.headers=[
        {headerText: 'UserId', field: 'userId'},
        {headerText: 'SISId/EId', field: 'uniqueId'},
        {headerText: 'Role', field: 'role'},
        {headerText: 'FirstName', field: 'firstName'},
        {headerText: 'LastName', field: 'lastName'},
        {headerText: 'Username', field: 'username'},
        {headerText: 'PasswordChangeRequired', field: 'passwordChangeRequired'},
        {headerText: 'TempPassword', field: 'tempPassword'}
    ]
}
}